<template>
    <div>
        <div class="bg-gradient-to-r from-tertiary-a to-tertiary-b py-32">
            <header class="max-w-md mx-auto px-4 text-center py-12">
                <h1 class="h2">{{ strings.title }}</h1>
            </header>
        </div>
        <transition-group name="portal-fade">
            <div v-if="loading" class="absolute py-32 left-0 w-full">
                <p class="sr-only">{{ strings.loading }}</p>
                <LoadingSpinner borderColourClass="border-mid-grey" class="mx-auto" />
            </div>
            <div v-else>
                <!-- User profile -->
                <section class="max-w-lg mx-auto px-2 md:px-4 mt-20 sm:mt-24">
                    <h2 class="h3 mb-10">{{ strings["profile-title"] }}</h2>
                    <div class="flex items-center justify-between mb-4 pb-4 border-b border-light-grey" id="basics-sect">
                        <span class="mr-4">{{ strings.basics }}</span>
                        <router-link
                            :to="`/survey/${welcomeSurveyId}?slides=name_location_birthday_ethnicity_sex&scrollTo=basics-sect`"
                            :aria-label="strings['sr-basics']"
                            class="btn bg-black text-white"
                            data-cy="update-all"
                        >
                            {{ strings.update }}
                        </router-link>
                    </div>
                    <ul class="text-base">
                        <!-- Fullname & preferred name -->
                        <li class="flex items-center justify-between mb-4 pb-4 border-b border-light-grey">
                            <div class="mr-4">
                                <span class="block mb-px">{{ strings.name }}</span>
                                <span class="block text-mid-grey high-c-text-black">
                                    <span data-cy="acc-fullname">{{ user.fullname ? user.fullname : strings["not-entered"] }}</span>
                                    <span v-if="user.name" data-cy="name"> ({{ user.name }})</span>
                                </span>
                            </div>
                            <router-link
                                data-cy="update-name"
                                to="/survey/useraccount?slides=name"
                                :aria-label="strings['sr-name']"
                                class="btn bg-lightest-grey"
                            >
                                {{ strings.update }}
                            </router-link>
                        </li>
                        <!-- Date of birth -->
                        <li class="flex items-center justify-between mb-4 pb-4 border-b border-light-grey">
                            <div class="mr-4">
                                <span class="block mb-px">{{ strings.birthday }}</span>
                                <span data-cy="birthday">
                                    <span v-if="user.birthday" class="block text-mid-grey high-c-text-black">{{ $date(formattedBirthday).format("DD MMM YYYY") }}</span>
                                    <span v-else class="block text-mid-grey high-c-text-black">{{ strings["not-entered"] }}</span>
                                </span>
                            </div>
                            <router-link
                                data-cy="update-dob"
                                to="/survey/useraccount?slides=birthday"
                                :aria-label="strings['sr-birthday']"
                                class="btn bg-lightest-grey"
                            >
                                {{ strings.update }}
                            </router-link>
                        </li>
                        <!-- Sex assigned at birth -->
                        <li class="flex items-center justify-between mb-4 pb-4 border-b border-light-grey">
                            <div class="mr-4">
                                <span class="block mb-px">{{ strings.sex }}</span>
                                <span data-cy="sex" class="block text-mid-grey high-c-text-black capitalize">{{
                                    user.sex ? user.sex : strings["not-entered"]
                                }}</span>
                            </div>
                            <router-link
                                data-cy="update-sex"
                                to="/survey/useraccount?slides=sex"
                                :aria-label="strings['sr-sex']"
                                class="btn bg-lightest-grey"
                            >
                                {{ strings.update }}
                            </router-link>
                        </li>
                        <!-- Location -->
                        <li class="flex items-center justify-between mb-4 pb-4 border-b border-light-grey">
                            <div class="mr-4">
                                <span class="block mb-px">{{ strings.location }}</span>
                                <span data-cy="location" class="block text-mid-grey high-c-text-black">{{
                                    user.location ? user.location : strings["not-entered"]
                                }}</span>
                            </div>
                            <router-link
                                data-cy="update-location"
                                to="/survey/useraccount?slides=location"
                                :aria-label="strings['sr-location']"
                                class="btn bg-lightest-grey"
                            >
                                {{ strings.update }}
                            </router-link>
                        </li>
                        <!-- Ethnicity -->
                        <li class="flex items-center justify-between mb-4 pb-4 border-b border-light-grey" id="ethnicity-sect">
                            <!-- US [FDA guidelines format] - 2 questions, multiple responses -->
                            <div data-cy="ethnicity-us" v-if="ethnicityQuestionFormat === 'USA'" class="mr-4">
                                <span class="block mb-px">{{ strings.hispanic }}</span>
                                <span
                                    v-if="user.ethnicity && user.ethnicity.us && user.ethnicity.us.hispanic"
                                    class="block text-mid-grey high-c-text-black"
                                >
                                    <span v-for="(answer, i) in user.ethnicity.us.hispanic" :key="`answer-us-hispanic-${i}`">
                                        {{ answer }}<span v-if="i < user.ethnicity.us.hispanic.length - 1">, </span>
                                    </span>
                                </span>
                                <span v-else class="block text-mid-grey high-c-text-black">{{ strings["not-entered"] }}</span>
                                <span class="block mb-px mt-2">{{ strings.race }}</span>
                                <span
                                    v-if="user.ethnicity && user.ethnicity.us && user.ethnicity.us.race"
                                    data-cy="ethnicity-us-race"
                                    class="block text-mid-grey high-c-text-black"
                                >
                                    <span v-for="(answer, i) in user.ethnicity.us.race" :key="`answer-us-race-${i}`">
                                        {{ answer }}<span v-if="i < user.ethnicity.us.race.length - 1">, </span>
                                    </span>
                                </span>
                                <span v-else class="block text-mid-grey high-c-text-black">{{ strings["not-entered"] }}</span>
                            </div>
                            <!-- Non US [UK govnt guidelines format] - 2 questions, single response to each -->
                            <div v-else class="mr-4">
                                <span class="block mb-px">{{ strings.ethnicity }}</span>
                                <span
                                    v-if="user.ethnicity && user.ethnicity.uk && user.ethnicity.uk.broad"
                                    data-cy="ethnicity-uk-broad"
                                    class="block text-mid-grey high-c-text-black"
                                >
                                    {{ user.ethnicity.uk.broad }}
                                    <span v-if="user.ethnicity.uk.narrow" data-cy="ethnicity-uk-narrow"> - {{ user.ethnicity.uk.narrow }}</span>
                                    <span
                                        v-if="user.ethnicity.uk.narrow_custom_answer && user.ethnicity.uk.broad === 'Another ethnic group'"
                                        data-cy="ethnicity-uk-narrow-custom"
                                    >
                                        - {{ user.ethnicity.uk.narrow_custom_answer }}
                                    </span>
                                </span>
                                <span v-else data-cy="ethnicity-missing" class="block text-mid-grey high-c-text-black">{{
                                    strings["not-entered"]
                                }}</span>
                            </div>
                            <!-- Update link (in this case, the questions you see depend on your answer to the location question, so this answer may only be updated if we have a location) -->
                            <router-link
                                v-if="user.location"
                                data-cy="update-ethnicity"
                                to="/survey/useraccount?slides=ethnicity&scrollTo=ethnicity-sect"
                                :aria-label="strings['sr-ethnicity']"
                                class="btn bg-lightest-grey"
                            >
                                {{ strings.update }}
                            </router-link>
                        </li>
                        <li class="flex items-center justify-between mb-4 pb-4 border-b border-light-grey">
                            <div class="mr-4">
                                <span class="block mb-px">{{ strings.email }}</span>
                                <span class="block text-mid-grey high-c-text-black" data-cy="email">{{ user.email }}</span>
                            </div>
                        </li>
                        <!-- Phone number / address / other optional core info in future? -->
                    </ul>
                    <!-- Password reset -->
                    <div class="relative w-full mt-8" style="min-height: 3rem">
                        <transition-group name="portal-fade" mode="out-in" appear>
                            <div
                                v-if="passwordResetProcessing"
                                key="process"
                                class="w-full absolute left-0 flex items-center"
                                style="min-height: 3rem"
                            >
                                <LoadingSpinner :diameterRem="1.4" borderColourClass="border-mid-grey" borderWidthClass="border-2" />
                            </div>
                            <div
                                v-else-if="passwordResetSent"
                                key="sent"
                                class="flex justify-center items-center w-full absolute left-0"
                                data-cy="password-reset-sent"
                                style="min-height: 3rem"
                            >
                                <div class="w-8 h-8 bg-success flex items-center justify-center rounded-full mr-2">
                                    <div class="portal-tick"></div>
                                </div>
                                <span class="block text-lighter">{{ strings["reset-success"] }}</span>
                            </div>
                            <div v-else key="initial" class="w-full absolute left-0 flex items-center" style="min-height: 3rem">
                                <button data-cy="password-reset" class="btn bg-black text-white w-full" @click="sendResetLink">
                                    {{ strings["request-reset"] }}
                                </button>
                            </div>
                        </transition-group>
                    </div>
                </section>
                <!-- Medical history -->
                <section v-if="!portalConfig.hide_medical_history" class="max-w-lg mx-auto px-2 md:px-4 mt-20 sm:mt-24">
                    <h2 class="h3 mb-10">{{ strings["med-hist-title"] }}</h2>
                    <div class="flex items-center justify-between mb-10 pb-4 border-b border-light-grey">
                        <span class="mr-4">{{ strings["med-hist"] }}</span>
                        <router-link
                            data-cy="update-med-hist"
                            to="/survey/useraccount?slides=medical"
                            :aria-label="strings['sr-med-hist']"
                            class="btn bg-black text-white"
                        >
                            {{ strings.update }}
                        </router-link>
                    </div>
                    <ul v-if="diagnosedConditions.length > 0" class="text-base">
                        <li
                            v-for="diagnosis in diagnosedConditions"
                            :key="`diagnosis-${diagnosis.conditionKey}`"
                            :data-cy="`diagnosis-${diagnosis.conditionKey}`"
                            class="p-4 bg-lightest-grey mb-3 rounded flex items-center justify-between"
                        >
                            <span>{{ diagnosis.conditionName }}</span>
                            <button
                                :aria-label="strings['sr-remove-diagnosis'].replace('{condition}', diagnosis.conditionName)"
                                class="w-10 h-10 bg-white rounded-full flex items-center justify-center"
                                :disabled="removingDiagnosis"
                                data-cy="delete"
                                @click="deleteDiagnosis(diagnosis.diagnosisId, diagnosis.conditionKey)"
                            >
                                <svg class="use-svg w-3 h-3">
                                    <use xlink:href="#close" />
                                </svg>
                            </button>
                        </li>
                    </ul>
                </section>
                <!-- Privacy -->
                <section class="max-w-lg mx-auto px-2 md:px-4 mt-20 sm:mt-24">
                    <h2 class="h3 mb-10">{{ strings["privacy-title"] }}</h2>
                    <router-link class="btn bg-black text-white w-full mb-8 text-center" to="/privacy">Read our privacy policy</router-link>
                    <div class="text-center text-base">
                        Need to
                        <button data-cy="delete-data" class="text-bolder border-b inline-block" @click="deleteAllData">delete all your data</button>
                        or
                        <button data-cy="delete-account" class="text-bolder border-b inline-block" @click="deactivateAccount">
                            deactivate your account</button
                        >?
                    </div>
                </section>
            </div>
        </transition-group>
    </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { User, Diagnosis, Condition } from "@/data/models";
import text from "./accountText";
import LoadingSpinner from "@/components/ui/loadingSpinner";

export default {
    name: "Account",
    components: {
        LoadingSpinner,
    },
    computed: {
        ...mapFields(["welcomeSurveyId"]),
        user() {
            return User.query().first();
        },
        ethnicityQuestionFormat() {
            if (this.user.location && this.user.location.match(/ ?USA$/) !== null) return "USA";
            return "UK";
        },
        diagnoses() {
            return Diagnosis.query().where("user", this.user.id).all();
        },
        diagnosedConditions() {
            return this.diagnoses.map((d) => {
                const conditionId = d.condition_id;
                const condition = Condition.query().where("id", conditionId).first();
                // A diagnosis object will give us the condition id, but we need that plus condition information
                return {
                    conditionId,
                    conditionName: condition.name,
                    conditionKey: condition.key,
                    diagnosisId: d.id,
                };
            });
        },
        formattedBirthday() {
            if (!this.user?.birthday) return null;
            // We expect the date to be returned from the database as a datetime object, with time set to 00:00:00 UTC
            // It will look like this: 1990-01-12T00:00:00+00:00. We need to be careful that in formatting it is not 
            // 'translated' to local time and as a side effect goes a day forward or back. 
            // (That would happen if we did new Date(this.user.birthday) in a non-GMT timezone)
            const yyyy_mm_dd = this.user.birthday.split("T")[0];
            const y_m_d_array = yyyy_mm_dd.split("-");
            // So we'll take the y, m, d nummbers only and pass them into new date without time params 
            // (note that argh, the month is 0 indexed), otherwise we'd just use ...y_m_d_array below
            return new Date(y_m_d_array[0], y_m_d_array[1] - 1, y_m_d_array[2]);
        }
    },
    data() {
        return {
            loading: true,
            passwordResetProcessing: false,
            passwordResetSent: false,
            removingDiagnosis: false,
            strings: text,
        };
    },
    created() {
        this.fetchInfo();
    },
    methods: {
        async fetchInfo() {
            await Promise.all([this.$api.user.read_user(), this.$api.user.read_my_diagnoses(), this.$api.user.read_my_diagnosed_conditions()]);
            this.loading = false;
            this.$emit("check-scroll-to");
        },
        sendResetLink() {
            this.passwordResetProcessing = true;
            this.$api.auth
                .reset(this.user.email)
                .then(() => {
                    this.passwordResetProcessing = false;
                    this.passwordResetSent = true;
                })
                .catch(() => {
                    this.$toast({
                        type: "error",
                        title: "Sorry, we had a problem sending a password reset link. Please get in touch and we'll figure out what happened.",
                    });
                    this.passwordResetProcessing = false;
                });
        },
        async deleteDiagnosis(diagnosisId, conditionKey) {
            // Show a warning before deleting a diagnosis
            const warningResult = await this.$choiceSwal.fire({
                title: this.strings.warnings["remove-diagnosis"].title,
                text: this.strings.warnings["remove-diagnosis"].text,
                confirmButtonText: this.strings.warnings["remove-diagnosis"].confirm,
                showCancelButton: true,
            });
            // Only proceed on confirmation
            if (warningResult.isConfirmed) {
                this.removingDiagnosis = true;
                await this.$api.user.delete_diagnosis_for_condition_key(diagnosisId, conditionKey);
                this.removingDiagnosis = false;
            }
        },
        async deleteAllData() {
            // Show a warning before deleting all data
            const warningResult = await this.$choiceSwal.fire({
                title: this.strings.warnings["delete-data"].title,
                text: this.strings.warnings["delete-data"].text,
                confirmButtonText: this.strings.warnings["delete-data"].confirm,
                showCancelButton: true,
            });
            // Only proceed on confirmation
            if (warningResult.isConfirmed) {
                this.loading = true;
                await this.$api.user.delete_all_data();
                this.$nextTick(() => {
                    // Re-fetch all information after deletion
                    this.fetchInfo();
                });
            }
        },
        async deactivateAccount() {
            // Show a warning before deleting all data
            const warningResult = await this.$choiceSwal.fire({
                title: this.strings.warnings["delete-account"].title,
                text: this.strings.warnings["delete-account"].text,
                confirmButtonText: this.strings.warnings["delete-account"].confirm,
                showCancelButton: true,
            });
            // Only proceed on confirmation
            if (warningResult.isConfirmed) {
                this.loading = true;
                this.$api.user.delete_account().then(() => {
                    setTimeout(async () => {
                        await this.$api.auth.logout();
                        if (window.Intercom) window.Intercom("shutdown");
                        window.location.href = "/";
                    }, 1000);
                });
            }
        },
    },
};
</script>
