/* Recently we heard from Smartling that it would make the process of sorting out translations much
easier if we can keep hard coded text in a separate file to functional code. I have started to implement
this on all portal files I'm working on so that with the portal project this stage can be as easy as possible once we reach it.
For strings which include variables, use any placeholder, e.g. {name} and the real variable can be slotted in in the vue html */

export default {
    loading: "Loading...",
    "not-entered": "Not entered",
    title: "Take control of your personal data & settings",
    "profile-title": "Your profile",
    basics: "Update all",
    "sr-basics": "Update all your basic information",
    name: "Name",
    "sr-name": "Update your name information",
    birthday: "Date of birth",
    "sr-birthday": "Update your date of birth",
    sex: "Sex as assigned at birth",
    "sr-sex": "Update your sex as assigned at birth",
    location: "Location",
    "sr-location": "Update your location",
    hispanic: "Hispanic, Latino/a, or of Spanish origin",
    race: "Race",
    ethnicity: "Ethnicity",
    "sr-ethnicity": "Update your ethnicity",
    email: "Email",
    update: "Update",
    "request-reset": "Request password reset",
    "reset-success": "That's done - check your inbox",
    "med-hist-title": "Your health",
    "med-hist": "Medical history",
    "sr-med-hist": "Update your medical history",
    "sr-remove-diagnosis": "Remove diagnosis of {condition}",
    "privacy-title": "Your privacy",
    warnings: {
        "remove-diagnosis": {
            title: "Are you sure you want to delete this diagnosis?",
            text: "Deleting this diagnosis means that we will no longer be able to let you know about some research you may be eligible for.",
            confirm: "Yes, delete it",
        },
        "delete-data": {
            title: "Are you sure?",
            text: "This action will delete all responses you have entered and your genome files. It will also withdraw you from studies that you've enrolled in on this platform. This account will go back to the blank state of just having been registered.",
            confirm: "Yes, delete it",
        },
        "delete-account": {
            title: "Are you sure?",
            text: "This action will delete all data associated with this account and your account profile. You will no longer be able to log in.",
            confirm: "Yes, delete my account",
        },
    },
};
